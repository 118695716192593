import Swiper, { Autoplay, Navigation } from 'swiper';

Swiper.use([Autoplay, Navigation])

new Swiper('.cms-swiper', {
    slidesPerView: 1,
    loop: true,
    spaceBetween: 0,
    resistance: false,
    navigation: {
        nextEl: '.btn-next',
        prevEl: '.btn-prev',
    },
    autoplay: {
        delay: 2000,
    },
    breakpoints: {
        575: {
            slidesPerView: 2,
            spaceBetween: 10,
        },
        1279: {
            slidesPerView: 8,
            spaceBetween: 10,
        }
    },
})
